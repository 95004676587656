/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from "react"
import Media from "react-media"
import { Link, graphql } from "gatsby"
import Layout from "../templates/pageLayout"
import ProjectCard from "../components/projectCard.js"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import * as portfolioStyles from "./portfolio.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayLongerCopy: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayLongerCopy: true })
  }

  render() {
    const {
      breadcrumb: { crumbs },
    } = this.props.pageContext

    return (
      <Layout title="Our Portfolio">
        <div className="container">
          <div className={portfolioStyles.portfolioContainer}>
            <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <h1>OUR PORTFOLIO</h1>
              <p>
                We take pride in our work and our portfolio is where we showcase
                selected projects.{" "}
                {this.state.displayLongerCopy && (
                  <Media
                    queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px)",
                    }}
                  >
                    {matches => (
                      <Fragment>
                        {matches.medium && (
                          <span>
                            So please don't be surprised if you don't see many
                            projects.{" "}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </Media>
                )}
                To see our day to day work please visit our{" "}
                <a
                  href="https://www.facebook.com/tintexportsmouth/"
                  rel="nofollow"
                >
                  Facebook{" "}
                </a>
                and{" "}
                <a
                  href="https://www.instagram.com/tintex_portsmouth/"
                  rel="nofollow"
                >
                  {" "}
                  Instagram
                </a>
                !
              </p>
            </ScrollAnimation>
            <PortfolioContent data={this.props.data} />
          </div>
        </div>
      </Layout>
    )
  }
}

class PortfolioContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSubCategory: "View All",
      selectedCategory: "View All",
      projects: [],
    }
  }

  componentDidMount() {
    const { data } = this.props
    const categories = [
      "View All",
      "Automotive",
      "Residential",
      "Marine",
      "Aviation",
    ]
    let subCategories = ["View All", "PPF"]
    let automotiveSubCategories = [
      "Active Exhaust",
      "Ceramic Coating",
      "Classic Restoration",
      "Dechroming",
      "Decals",
      "Detailing",
      "End Of Lease",
      "Headlight Protection",
      "Headlight Restoration",
      "Lights Tinting",
      "PPF",
      "Paintwork Enhancement",
      "Paintwork Protection",
      "Paint Correction",
      "Vinyl Wrapping",
      "Water Repellent",
      "Window Tinting",
      "Wrap Removal",
    ]

    const categoriesList = document.querySelector("#categoryFilter")
    const subCategoriesList = document.querySelector("#subCategoryFilter")
    const underline = document.createElement("div")
    underline.classList.add("filterItem__activeUnderline")

    {
      for (let i = 0; i < categories.length; i++) {
        const div = document.createElement("div")
        categoriesList.appendChild(div)

        div.classList.add("filterItem")
        div.innerHTML = categories[i]
        div.setAttribute("id", categories[i] + "Cat")

        if (categories[i] === this.state.selectedCategory) {
          div.classList.add("filterItem__active")
          div.appendChild(underline)
        }

        div.addEventListener("click", () => {
          this.setState({
            selectedCategory: categories[i],
          })
        })
      }
    }

    {
      for (let i = 0; i < subCategories.length; i++) {
        const div = document.createElement("div")
        subCategoriesList.appendChild(div)

        div.classList.add("filterItem")
        div.innerHTML = subCategories[i]
        div.setAttribute("id", subCategories[i] + "SubCat")

        if (subCategories[i] === this.state.selectedSubCategory) {
          div.classList.add("filterItem__active")
        }

        div.addEventListener("click", () => {
          this.setState({
            selectedSubCategory: subCategories[i],
          })
        })
      }
    }

    this.setState({ projects: [] })

    data.allMdx.edges.map((project, index) => {
      this.setState(state => {
        const projects = state.projects.push(
          <Link to={project.node.fields.slug}>
            <ProjectCard
              project={project.node.frontmatter}
              link={project.node.fields.slug}
              styles={portfolioStyles}
              key={index}
            />
          </Link>
        )
      })
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let selectedCategory = this.state.selectedCategory
    let selectedSubCategory = this.state.selectedSubCategory

    const underline = document.createElement("div")
    underline.classList.add("filterItem__activeUnderline")

    const { data } = this.props

    if (selectedCategory || selectedSubCategory) {
      if (
        selectedCategory != prevState.selectedCategory ||
        selectedSubCategory != prevState.selectedSubCategory
      ) {
        this.setState({
          projects: [],
        })

        if (selectedCategory != prevState.selectedCategory) {
          let newCategory = document.getElementById(selectedCategory + "Cat")
          newCategory.appendChild(underline)
          newCategory.classList.add("filterItem__active")

          let prevCategory = document.getElementById(
            prevState.selectedCategory + "Cat"
          )
          prevCategory.removeChild(prevCategory.childNodes[1])
          prevCategory.classList.remove("filterItem__active")
        } else {
          let newCategory = document.getElementById(
            selectedSubCategory + "SubCat"
          )
          newCategory.appendChild(underline)
          newCategory.classList.add("filterItem__active")

          let prevCategory = document.getElementById(
            prevState.selectedSubCategory + "SubCat"
          )
          prevCategory.removeChild(prevCategory.childNodes[1])
          prevCategory.classList.remove("filterItem__active")
        }

        data.allMdx.edges.map((project, index) => {
          if (
            selectedCategory === "View All" &&
            selectedSubCategory === "View All"
          ) {
            this.setState(state => {
              const projects = state.projects.push(
                <ProjectCard
                  project={project.node.frontmatter}
                  link={project.node.fields.slug}
                  styles={portfolioStyles}
                  key={index}
                />
              )
            })
          } else if (
            project.node.frontmatter.category === this.state.selectedCategory &&
            this.state.selectedSubCategory === "View All"
          ) {
            this.setState(state => {
              const projects = state.projects.push(
                <ProjectCard
                  project={project.node.frontmatter}
                  link={project.node.fields.slug}
                  styles={portfolioStyles}
                  key={index}
                />
              )
            })
          } else if (
            project.node.frontmatter.category === this.state.selectedCategory &&
            project.node.frontmatter.subCategory ===
              this.state.selectedSubCategory
          ) {
            this.setState(state => {
              const projects = state.projects.push(
                <ProjectCard
                  project={project.node.frontmatter}
                  link={project.node.fields.slug}
                  styles={portfolioStyles}
                  key={index}
                />
              )
            })
          }
        })
      }
    }
  }

  render() {
    let { projects } = this.state

    let sorted = projects.sort((a, b) => {
      if (
        a.props.children.props.project.notable <
        b.props.children.props.project.notable
      ) {
        return 1
      }
      if (
        a.props.children.props.project.notable >
        b.props.children.props.project.notable
      ) {
        return -1
      }
      return 0
    })

    return (
      <div id={portfolioStyles.portfolioBody}>
        <div id="categoryFilter"></div>
        <FilterBtn />
        <div id="subCategoryFilter"></div>
        <div className={portfolioStyles.portfolioGrid}>{sorted}</div>
      </div>
    )
  }
}

class FilterBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isFilterOpen: false }

    this.openFilter = this.openFilter.bind(this)
  }

  openFilter() {
    this.setState(state => ({
      isFilterOpen: !state.isFilterOpen,
    }))
  }

  render() {
    return (
      <div className={portfolioStyles.dropDown}>
        <button className="buttonStyle1" onClick={this.openFilter}>
          Filter
        </button>
        {this.state.isFilterOpen && (
          <div className={portfolioStyles.dropDownContent}>
            <span>Yo</span>
          </div>
        )}
      </div>
    )
  }
}

export const query = graphql`
  query PortfolioQueryOld {
    allMdx(filter: { frontmatter: { type: { eq: "Project" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            type
            category
            subCategory
            services
            title
            description
            imgFile
            notable
          }
        }
      }
    }
  }
`
