// extracted by mini-css-extract-plugin
export var dropDownContent = "portfolio-module--dropDownContent--5ct3B";
export var filtersContainer = "portfolio-module--filtersContainer--TebBz";
export var portfolioBody = "portfolio-module--portfolioBody--aB4d6";
export var portfolioContainer = "portfolio-module--portfolioContainer--udamx";
export var portfolioGrid = "portfolio-module--portfolioGrid--z6Euu";
export var projectCard = "portfolio-module--projectCard--iI+RO";
export var projectDescription = "portfolio-module--projectDescription--Ka5kG";
export var projectFilterText = "portfolio-module--projectFilterText--JNs01";
export var projectFiltersContainer = "portfolio-module--projectFiltersContainer--ACu7U";
export var projectImg = "portfolio-module--projectImg--cI+7d";
export var projectTitle = "portfolio-module--projectTitle--wIAma";
export var subCategoryFilter = "portfolio-module--subCategoryFilter--pAjhU";
export var subCategoryFilterBtn = "portfolio-module--subCategoryFilterBtn--nTko7";
export var subCategoryItem = "portfolio-module--subCategoryItem--xHAdP";
export var subCategoryItem__active = "portfolio-module--subCategoryItem__active---XGcY";